import React from "react";

import { useState, ComponentProps } from "react";
import { PieChart } from "react-minimal-pie-chart";

const colors = [
  "03045E",
  "023E8A",
  "0077B6",
  "0096C7",
  "00B4D8",
  "48CAE4",
  "90E0EF",
  "CAF0F8",
  "03045E",
  "023E8A",
  "0077B6",
  "0096C7",
  "00B4D8",
  "48CAE4",
  "90E0EF",
  "CAF0F8",
  "03045E",
  "023E8A",
  "0077B6",
  "0096C7",
  "00B4D8",
  "48CAE4",
  "90E0EF",
  "CAF0F8",
  "03045E",
  "023E8A",
  "0077B6",
  "0096C7",
  "00B4D8",
  "48CAE4",
  "90E0EF",
  "CAF0F8",
];

const rawData = [
  { title: "TetraPak", value: 36, color: "#3C3D18" },
  { title: "Saint Gobain", value: 26.5, color: "#F8FC86" },
  { title: "Brown Forman", value: 14.5, color: "#BD0030" },
  { title: "Heineken", value: 13, color: "#AC002B" },
  { title: "Unilever", value: 11.5, color: "#980026" },
  { title: "Mars/Wrigley", value: 10, color: "#DC0037" },
  { title: "Ecolab", value: 10, color: "#3f4023" },
  { title: "Kerry Foods", value: 10, color: "#C60032" },
  { title: "Danone", value: 8.5, color: "#A00028" },
  { title: "Upfield", value: 8, color: "#D30035" },
  { title: "Bongrain", value: 7, color: "#FB114C" },
  { title: "Nestle", value: 4, color: "#B5012E" },
  { title: "Coca Cola", value: 4, color: "#CD0033" },
  { title: "Dawn Foods", value: 4, color: "#FB003F" },
  { title: "Molson Coors", value: 2.5, color: "#E40039" },
];

const data = rawData.map((entry, i) => {
  return {
    ...entry,
    color: "#" + colors[i],
  };
});

const lineWidth = 45;

const DesktopExperience = () => {

  const [selected, setSelected] = useState(0);
  const [hovered, setHovered] = useState(undefined);


  return (
    <>
      <section className="ml-16 ">
        <div className="w-11/12">
          <h4 className="text-md font-sTitle text-grey tracking-wider">
            DIVERSE EXPERIENCE PROVIDING DIVERSE OUTCOMES
          </h4>
          <h1 className="text-5xl xl:text-8xl pb-4 font-bTitle">
            Our Experience
          </h1>
          <p className="mt-4 font-sTitle text-base">
            Typically we work in 3 phases. First we train. Just as the origin of
            the word describes, we will lead you along an improvement path that
            is customized for the “losses” and ‘wastes” that you want to attack.
            Once those skills are passed along, we then focus on coaching. Here
            we create the internal skill sets to convert your performance
            platform into an improvement platform. Finally our role becomes to
            mentor. Our many years of experience allows us to advise on many
            topics and we become an intimate friend and sage counselor. Our past
            clients can explain their experience much better so feel free to ask
            for personal references.
          </p>
        </div>
      </section>

      <section className="mx-16 px-8 pt-8 flex flex-row flex-wrap">
        <div className="w-6/12 shadow-xl  rounded-lg">
          <PieChart
            data={data}
            radius={(PieChart.defaultProps?.radius || 50) - 12}
            totalValue={169.5}
            style={{ height: "40vh", padding: "16px" }}
            lineWidth={lineWidth}
            segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
            //segmentsShift={(index) => (index === selected ? 6 : 1)}
            animate
            label={({ dataEntry }) => dataEntry.title}
            labelPosition={100 - lineWidth / 2}
            labelStyle={{
              fill: "#ggg",
              opacity: 1,
              pointerEvents: "none",
              fontSize: "4px",
              fontFamily: "questrial",
              fontWeight: "bolder",
            }}
            labelPosition={112}
            background="#bfbfbf"
            rounded
          />
        </div>
        <div className="w-6/12 ml-auto flex flex-row flex-wrap ">
          <Experience years="42 years" industry="Food" />
          <Experience years="34 years" industry="Beverages" />
          <Experience
            years="63 years"
            industry="Packaging and Tape Solutions"
          />
          <Experience years="10 years" industry="Confectionary Industry" />
          <Experience years="12 years" industry="Personal Care" />
          <Experience years="10 years" industry="Sanitation" />
        </div>
      </section>
    </>
  );
}

const MobileExperience = () => {

  const [selected, setSelected] = useState(0);
  const [hovered, setHovered] = useState(undefined);


  return (
    <>
      <section className="mx-8 pt-4">
        <div className="">
          <h4 className="text-md font-sTitle text-grey tracking-wider">
            DIVERSE EXPERIENCE PROVIDING DIVERSE OUTCOMES
          </h4>
          <h1 className="text-4xl xl:text-8xl pb-4 font-bTitle">
            Our Experience
          </h1>
          <p className="mt-2 font-sTitle text-base">
            Typically we work in 3 phases. First we train. Just as the origin of
            the word describes, we will lead you along an improvement path that
            is customized for the “losses” and ‘wastes” that you want to attack.
            Once those skills are passed along, we then focus on coaching. Here
            we create the internal skill sets to convert your performance
            platform into an improvement platform. Finally our role becomes to
            mentor. Our many years of experience allows us to advise on many
            topics and we become an intimate friend and sage counselor. Our past
            clients can explain their experience much better so feel free to ask
            for personal references.
          </p>
        </div>
      </section>

      <section className="mx-8 pt-8 flex flex-row flex-wrap">
        <div className="shadow-md rounded-lg mx-auto">
          <PieChart
            data={data}
            radius={PieChart.defaultProps.radius - 12}
            totalValue={169.5}
            style={{ height: "40vh", padding: "16px" }}
            lineWidth={lineWidth}
            segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
            //segmentsShift={(index) => (index === selected ? 6 : 1)}
            animate
            label={({ dataEntry }) => dataEntry.title}
            labelPosition={100 - lineWidth / 2}
            labelStyle={{
              fill: "#ggg",
              opacity: 1,
              pointerEvents: "none",
              fontSize: "3px",
              fontFamily: "questrial",
              fontWeight: "bolder",
            }}
            labelPosition={112}
            background="#bfbfbf"
            rounded
          />
        </div>
        <div className="flex flex-row flex-wrap justify-center  ">
          <MobileExperienceItem years="42 years" industry="Food" />
          <MobileExperienceItem years="34 years" industry="Beverages" />
          <MobileExperienceItem
            years="63 years"
            industry="Packaging and Tape Solutions"
          />
          <MobileExperienceItem years="10 years" industry="Confectionary Industry" />
          <MobileExperienceItem years="12 years" industry="Personal Care" />
          <MobileExperienceItem years="10 years" industry="Sanitation" />
        </div>
      </section>
    </>
  );
}

export default function OurExperience() {
  
  var width = window.innerWidth
  return (
      width > 800 ?
      <DesktopExperience/> :
      <MobileExperience/>
  );
}
function Experience({ years, industry }) {

  return (
    <div className="shadow-lg m-1 bg-blue-400 text-white rounded-md w-5/12  px-2 flex flex-col items-center place-content-center">
      <h4 className="font-bTitle font-black text-lg xl:text-lg"> {years} </h4>
      <h6 className="font-sTitle font-normal text-md xl:text-sm">
        {" "}
        {industry}{" "}
      </h6>
    </div>
  );
}

function MobileExperienceItem({ years, industry }) {

  return (
    <div className="shadow-lg m-1 bg-blue-400 text-white rounded-md w-5/12 px-1 flex flex-col items-center place-content-center">
      <h4 className="font-bTitle font-black text-md"> {years} </h4>
      <h6 className="font-sTitle font-normal text-sm">
        {industry}
      </h6>
    </div>
  );
}
