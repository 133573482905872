import React from "react";

var files = {};
var context = require.context("../assets", true, /\.(png)$/);

context.keys().forEach((originalFileName) => {
  var filename = originalFileName.replace("./", "");
  filename = filename.replace(".png", "");
  files[filename] = context(originalFileName);
});

const DesktopAbout = () => {

  return (
    <div className="overflow-scroll">
      <section className="ml-16 pt-4">
        <div className="w-11/12">
          <h4 className="text-md font-sTitle text-grey tracking-wider">
            TRUST IN EXPERIENCE
          </h4>
          <h1 className="text-5xl xl:text-8xl font-bTitle"> Our Team </h1>
          <p className="mt-4 font-sTitle">
            The Appleby Group is an international team that was recently
            re-organized and cross trained in order to support local geography’s
            without the need for cross border travel. All current health
            warnings and safety protocols are respected while visiting your site
            and of course online virtual support techniques have been mastered.
            Our backgrounds and skill sets cross many improvement boundaries
            including, TPM, TQM, WCM, Lean Six Sigma, and all aspects of
            Operational Excellence. With experience in both manufacturing and
            non-manufacturing environments, we can repair or create
            relationships on both sides of your business.
          </p>
        </div>
      </section>

      <section className="mt-16 ml-16 w-11/12 ">
        <h2 className="text-2xl xl:text-4xl font-bTitle font-semibold">
          Our Managing Partners:
        </h2>

        <div className="mt-8 mb-16 flex flex-row flex-wrap justify-around">
          <ManagingEmployee
            image={files.chrisImage.default}
            employeeName="Chris Mouriopoulos"
            employeeInfo="In the arena of Transformational Improvement, Chris’s main strength is to take known methodologies and make them work in any manufacturing or non-manufacturing environment. His secret is to use the resources at hand and help them understand their role in the change and in the long term sustainability of that change. Experienced with clients from 21 people up to 5200 people, the right mix of time-based improvement techniques coupled with real business drivers will create the System."
          />
         
        </div>
      </section>

      <section className="ml-16 space-x-1 flex flex-row flex-wrap w-11/12 justify-around">
        <Employee
          image={files.mauricioImage.default}
          employeeName="Mauricio Vivas"
          employeeInfo="Mauricio is a powerful influencer and problem solver, using continuous improvement, project management and training strengths to deliver transformational change and added value against stretch goals. Contributing experience from internationally diverse and challenging environments. Delivering step changes in factories as people-oriented leader and coach of multi-functional teams. Improving quality, cost, safety, and performance by engaging with key partners"
        />
        <Employee
          image={files.allenImage.default}
          employeeName="Allen Ko"
          employeeInfo="Highly experienced consultant in various continuous improvement methodologies including Lean/Six Sigma black belt.  Led Toyota Production System projects in various sectors of industry to include food and beverage, building materials, glass and ceramics and paperboard packaging.  Allen has served as author, coach and project leader within global multi-site and headquarter environments.  Allen has dynamically created and led custom factory workshops with building sustainable follow-through on improvement activities, and ensuring cost-benefit results were made to impact the bottom line.  With Allen's quality and production background, he's demonstrated the ability to resolve a variety of production issues by identifying the loss and then eliminating it."
        />
        <Employee
          image={files.mikeImage.default}
          employeeName="Mike Smith"
          employeeInfo="While Mike draws upon 30 years of world-class manufacturing experience in general management, large-scale transformation, leadership performance, organizational development, employee engagement, labor relations, technology innovation, and operational excellence, his demonstrated ability to drive organizational culture shifts to support corporate objectives for sustainable performance is the hallmark of his success. Mike has worked with companies across industries as an advisor to C-level decision makers in quickly assessing business capabilities/constraints and putting the right people, operations, repeatable processes, metrics, and systems in place to achieve objectives."
        />
        {/*<Employee*/}
        {/*  image={files.grantImage.default}*/}
        {/*  employeeName="Grant Douangsouri"*/}
        {/*  employeeInfo="Grant is an experienced Senior Consultant with a 20 year background in*/}
        {/*    manufacturing consulting. His strongest attribute is client relationships where his interpersonal skills immediately make a difference. Skilled in managing, delegating and assigning complex timelines and projects. Proficiency in TPM and WCM processes & methodology along with the ability to read audiences of all levels gives Grant his reputation as consultant that every client wants to hire! */}
        {/*    "*/}
        {/*/>*/}
        {/* <Employee
          image={files.randyImage.default}
          employeeName="Randy Jenkins"
          employeeInfo="Randy brings over 30 years’ Manufacturing Experience in various management roles including Maintenance, Operations, Quality, Production Planning, Continuous Improvements, and Continuous Improvement Consulting. With a structured focus on team engagement for sustainable process improvements with a foundation of  WCM, Change Management, and TPM. He is a certified Process Kaizen Engineer with a proven strategy of getting Win-Win situations. While driving real business results by eliminating company losses, he is able to quickly gain the trust and enthusiasm from all levels of an organization. By the stratification of company losses, pinpointing where the losses are occurring, and by using proven methodologies to attack those losses, a more productive facility with lower operating cost is always the result. With his coaching and mentoring philosophy, the results will be sustainable long into the future; thus, changing how you will do business in the future."
        /> */}
        <Employee
          image={files.homerImage.default}
          employeeName="Homer Arvanitis"
          employeeInfo="As an accredited senior appraiser of the American Society of Appraisers in Business Valuations, Mr. Homer Arvanitis brings valuable insight to both sell-side and buy-side transactions. He has specialized in mergers & acquisitions, business valuations, and
            economic loss analyses since 1981, completing assignments for both small and
            large businesses in diverse industry sectors domestically and internationally. Mr. Arvanitis’ experience includes leading both the Valuation Group and Food & Beverage Practice Group at one of North America’s leading mid-market M&A advisory groups.
            This expertise gives Homer the ability to support Appleby’s Private Equity partners both in their buy and sell efforts."
        />
        <Employee
          image={files.tedImage.default}
          employeeName="Ted Long"
          employeeInfo="Ted’s front line experience in Production Management spans over the past 20 years. A strong TPM background with experience in all facets of Continuous Improvement culmination in the highest achievement of both  JIPM Excellence and Consistency Awards.
        Excels in implementing and improving processes and routines while working with employees at every level to build a culture capable of sustaining change. "
        />
        <Employee
          image={files.luisImage.default}
          employeeName="Luis Gonzalez"
          employeeInfo="18 years´ experience in management and operations consulting - focused on Supply Chain, Project Management, Lean Management, TPM methodologies and Six Sigma
            Experience in industrial and consumer business within, finance, supply chain, project management (Information Technology) and implementation of continuous improvement projects 
            International experience in WCM consulting and training (Costa Rica, Bahamas, Martinique, Suriname, Austria, Nigeria, Russia, Madrid, St. Lucia, Brazil)"
        />

        <Employee
          image={files.candaceImage.default}
          employeeName="Candice St. Cin"
          employeeInfo="Candace has 15 years Experience in WCM/TPM implementation.
            Recipient of 2 Japanese Institute of Plant Maintenance Awards: TPM Excellence Award Category B and Award for Excellence in Consistent TPM Commitment.  Her areas of expertise include: coaching and mentoring, kaizen teams and pillar methodologies."
        />

        <Employee
          image={files.jimImage.default}
          employeeName="Jim Ridge"
          employeeInfo="Jim is a videographer and accomplished illustrator with strong conceptual skills. One of his unique talents is using his 37 years of manufacturing experience, combined with his graphic and video skills, to make difficult-to-understand business concepts easy to understand with simple visual stories. Jim also advises and trains clients on World Class Organizational Manufacturing specializing on Change Leadership for Lean Manufacturing. As well he conducts highly interactive and dynamic workshops on visual communication and story telling. "
        />
        <Employee
          image={files.toddImage.default}
          employeeName="Todd Hass"
          employeeInfo=" Todd's key strength is aligning organizational resources to optimize
          value chain / supply chain end-to-end networks. Todd actively leads teams to
          higher levels of engagement and builds a problem solving culture in
          organizations with increased responsiveness and reliability. 20+ years of
          operational experience in multiple industries improving manufacturing
          processes, supplier performances, inventory optimization, logistics, and
          warehouse flow. "
        />
         <Employee
          image={files.robertImage.default}
          employeeName="Robert Logan"
          employeeInfo=" In the field of Innovation and Product Development, Rob has extensive leadership and systems experience to help drive improvement and growth in your organization. He brings leadership experience from manufacturing, quality, maintenance, technical service and research and development from the automotive and metals industries, but can also draw upon other industries as appropriate.  Rob has utilized the Stage-Gate and Portfolio management approach in customer focused organizations to manage the innovation process.  Whether it be for specific projects or a broad approach promoting and creating an innovative culture, Rob can help your organization take the necessary steps to a higher level. "
        />
        <Employee
          image={files.sunilImage.default}
          employeeName="Sunil Malhotra"
          employeeInfo="Senior Digital Advisor: (IT infrastructure, inventory control, data collection)Vast experience in digital solutions provided in a consultant role.  Spent over 17 years in Management roles including System Analyst and CI Manager.I am now a subject matter expert on Loss Modeling, OEE database, Work Sampling, QA Data collection, Loss Data collection, Business Process Excellence, and overall IT systems integration and improvement. I have achieved this expertise through implementations of models in 100’s of factories in various companies across different sectors (e.g. cosmetics, alcohol, steel, food and beverages). My special focus is on loss analysis and data collection to find “unidentified” opportunities."
        />
      </section>
    </div>
  );
}

const width = window.innerWidth

const MobileAbout = () => {
  return (
    <div className="overflow-scroll">
      <section className="mx-8 pt-4">
        <div className="">
          <h4 className="text-md font-sTitle text-grey tracking-wider">
            TRUST IN EXPERIENCE
          </h4>
          <h1 className="text-5xl font-bTitle"> Our Team </h1>
          <p className="mt-2 font-sTitle">
            The Appleby Group is an international team that was recently
            re-organized and cross trained in order to support local geography’s
            without the need for cross border travel. All current health
            warnings and safety protocols are respected while visiting your site
            and of course online virtual support techniques have been mastered.
            Our backgrounds and skill sets cross many improvement boundaries
            including, TPM, TQM, WCM, Lean Six Sigma, and all aspects of
            Operational Excellence. With experience in both manufacturing and
            non-manufacturing environments, we can repair or create
            relationships on both sides of your business.
          </p>
        </div>
      </section>

      <section className="mt-8 mx-8 ">
        <h2 className="text-2xl font-bTitle font-semibold">
          Our Managing Partners:
        </h2>

        <div className="mt-8 mb-16 flex flex-row flex-wrap justify-around">
          <MobileManagingEmployee
            image={files.chrisImage.default}
            employeeName="Chris Mouriopoulos"
            employeeInfo="In the arena of Transformational Improvement, Chris’s main strength is to take known methodologies and make them work in any manufacturing or non-manufacturing environment. His secret is to use the resources at hand and help them understand their role in the change and in the long term sustainability of that change. Experienced with clients from 21 people up to 5200 people, the right mix of time-based improvement techniques coupled with real business drivers will create the System."
          />

        </div>
      </section>

      <section className="mx-8 flex flex-row flex-wrap justify-around">

      <h2 className="text-2xl font-bTitle font-semibold w-full">
          The Team:
        </h2>

        <Employee
          image={files.mauricioImage.default}
          employeeName="Mauricio Vivas"
          employeeInfo="Mauricio is a powerful influencer and problem solver, using continuous improvement, project management and training strengths to deliver transformational change and added value against stretch goals. Contributing experience from internationally diverse and challenging environments. Delivering step changes in factories as people-oriented leader and coach of multi-functional teams. Improving quality, cost, safety, and performance by engaging with key partners"
        />
        <Employee
          image={files.allenImage.default}
          employeeName="Allen Ko"
          employeeInfo="Highly experienced consultant in various continuous improvement methodologies including Lean/Six Sigma black belt.  Led Toyota Production System projects in various sectors of industry to include food and beverage, building materials, glass and ceramics and paperboard packaging.  Allen has served as author, coach and project leader within global multi-site and headquarter environments.  Allen has dynamically created and led custom factory workshops with building sustainable follow-through on improvement activities, and ensuring cost-benefit results were made to impact the bottom line.  With Allen's quality and production background, he's demonstrated the ability to resolve a variety of production issues by identifying the loss and then eliminating it."
        />
        <Employee
          image={files.mikeImage.default}
          employeeName="Mike Smith"
          employeeInfo="While Mike draws upon 30 years of world-class manufacturing experience in general management, large-scale transformation, leadership performance, organizational development, employee engagement, labor relations, technology innovation, and operational excellence, his demonstrated ability to drive organizational culture shifts to support corporate objectives for sustainable performance is the hallmark of his success. Mike has worked with companies across industries as an advisor to C-level decision makers in quickly assessing business capabilities/constraints and putting the right people, operations, repeatable processes, metrics, and systems in place to achieve objectives."
        />
        {/*<Employee*/}
        {/*  image={files.grantImage.default}*/}
        {/*  employeeName="Grant Douangsouri"*/}
        {/*  employeeInfo="Grant is an experienced Senior Consultant with a 20 year background in*/}
        {/*    manufacturing consulting. His strongest attribute is client relationships where his interpersonal skills immediately make a difference. Skilled in managing, delegating and assigning complex timelines and projects. Proficiency in TPM and WCM processes & methodology along with the ability to read audiences of all levels gives Grant his reputation as consultant that every client wants to hire! */}
        {/*    "*/}
        {/*/>*/}
        {/* <Employee
          image={files.randyImage.default}
          employeeName="Randy Jenkins"
          employeeInfo="Randy brings over 30 years’ Manufacturing Experience in various management roles including Maintenance, Operations, Quality, Production Planning, Continuous Improvements, and Continuous Improvement Consulting. With a structured focus on team engagement for sustainable process improvements with a foundation of  WCM, Change Management, and TPM. He is a certified Process Kaizen Engineer with a proven strategy of getting Win-Win situations. While driving real business results by eliminating company losses, he is able to quickly gain the trust and enthusiasm from all levels of an organization. By the stratification of company losses, pinpointing where the losses are occurring, and by using proven methodologies to attack those losses, a more productive facility with lower operating cost is always the result. With his coaching and mentoring philosophy, the results will be sustainable long into the future; thus, changing how you will do business in the future."
        /> */}
        <Employee
          image={files.homerImage.default}
          employeeName="Homer Arvanitis"
          employeeInfo="As an accredited senior appraiser of the American Society of Appraisers in Business Valuations, Mr. Homer Arvanitis brings valuable insight to both sell-side and buy-side transactions. He has specialized in mergers & acquisitions, business valuations, and
            economic loss analyses since 1981, completing assignments for both small and
            large businesses in diverse industry sectors domestically and internationally. Mr. Arvanitis’ experience includes leading both the Valuation Group and Food & Beverage Practice Group at one of North America’s leading mid-market M&A advisory groups.
            This expertise gives Homer the ability to support Appleby’s Private Equity partners both in their buy and sell efforts."
        />
        <Employee
          image={files.tedImage.default}
          employeeName="Ted Long"
          employeeInfo="Ted’s front line experience in Production Management spans over the past 20 years. A strong TPM background with experience in all facets of Continuous Improvement culmination in the highest achievement of both  JIPM Excellence and Consistency Awards.
        Excels in implementing and improving processes and routines while working with employees at every level to build a culture capable of sustaining change. "
        />
        <Employee
          image={files.luisImage.default}
          employeeName="Luis Gonzalez"
          employeeInfo="18 years´ experience in management and operations consulting - focused on Supply Chain, Project Management, Lean Management, TPM methodologies and Six Sigma
            Experience in industrial and consumer business within, finance, supply chain, project management (Information Technology) and implementation of continuous improvement projects 
            International experience in WCM consulting and training (Costa Rica, Bahamas, Martinique, Suriname, Austria, Nigeria, Russia, Madrid, St. Lucia, Brazil)"
        />

        <Employee
          image={files.candaceImage.default}
          employeeName="Candice St. Cin"
          employeeInfo="Candace has 15 years Experience in WCM/TPM implementation.
            Recipient of 2 Japanese Institute of Plant Maintenance Awards: TPM Excellence Award Category B and Award for Excellence in Consistent TPM Commitment.  Her areas of expertise include: coaching and mentoring, kaizen teams and pillar methodologies."
        />

        <Employee
          image={files.jimImage.default}
          employeeName="Jim Ridge"
          employeeInfo="Jim is a videographer and accomplished illustrator with strong conceptual skills. One of his unique talents is using his 37 years of manufacturing experience, combined with his graphic and video skills, to make difficult-to-understand business concepts easy to understand with simple visual stories. Jim also advises and trains clients on World Class Organizational Manufacturing specializing on Change Leadership for Lean Manufacturing. As well he conducts highly interactive and dynamic workshops on visual communication and story telling. "
        />
        <Employee
          image={files.toddImage.default}
          employeeName="Todd Hass"
          employeeInfo=" Todd's key strength is aligning organizational resources to optimize
          value chain / supply chain end-to-end networks. Todd actively leads teams to
          higher levels of engagement and builds a problem solving culture in
          organizations with increased responsiveness and reliability. 20+ years of
          operational experience in multiple industries improving manufacturing
          processes, supplier performances, inventory optimization, logistics, and
          warehouse flow. "
        />
        <Employee
          image={files.robertImage.default}
          employeeName="Robert Logan"
          employeeInfo=" In the field of Innovation and Product Development, Rob has extensive leadership and systems experience to help drive improvement and growth in your organization. He brings leadership experience from manufacturing, quality, maintenance, technical service and research and development from the automotive and metals industries, but can also draw upon other industries as appropriate.  Rob has utilized the Stage-Gate and Portfolio management approach in customer focused organizations to manage the innovation process.  Whether it be for specific projects or a broad approach promoting and creating an innovative culture, Rob can help your organization take the necessary steps to a higher level. "
        />
        <Employee
          image={files.sunilImage.default}
          employeeName="Sunil Malhotra"
          employeeInfo="Senior Digital Advisor: (IT infrastructure, inventory control, data collection).Vast experience in digital solutions provided in a consultant role.  Spent over 17 years in Management roles including System Analyst and CI Manager.I am now a subject matter expert on Loss Modeling, OEE database, Work Sampling, QA Data collection, Loss Data collection, Business Process Excellence, and overall IT systems integration and improvement. I have achieved this expertise through implementations of models in 100’s of factories in various companies across different sectors (e.g. cosmetics, alcohol, steel, food and beverages). My special focus is on loss analysis and data collection to find “unidentified” opportunities."
        />
      </section>
    </div>
  );
}

export default function AboutUs() {
  
  const width = window.innerWidth;

  return (
    width > 800 ? <DesktopAbout/> : <MobileAbout/>
  );
}

function Employee({ image, employeeName, employeeInfo }) {

  return (
    width > 800 ? 
    (<div className="w-1/4 my-8">
      <img src={image} className="mx-auto rounded-full max-h-64" />
      <h2 className="font-bTitle font-bold text-xl xl:text-3xl mt-8">
        {employeeName}
      </h2>
      <p className="font-sTitle font-normal text-sm xl:text-md mt-4">
        {employeeInfo}
      </p>
    </div>) : (
      <div className="my-4">
      <img src={image} className="mx-auto rounded-full max-h-48" />
      <h2 className="font-bTitle font-bold text-xl xl:text-3xl mt-8">
        {employeeName}
      </h2>
      <p className="font-sTitle font-normal text-sm xl:text-md mt-4">
        {employeeInfo}
      </p>
    </div>
    )

  );
}

function ManagingEmployee({ image, employeeName, employeeInfo }) {
  return (
    <div className="w-5/12  ">
      <img src={image} className="h-1/2 mx-auto rounded-full " />
      <h2 className="font-bTitle font-bold text-xl xl:text-3xl mt-8">
        {" "}
        {employeeName}{" "}
      </h2>
      <p className="font-sTitle font-normal text-sm xl:text-md mt-4">
        {" "}
        {employeeInfo}{" "}
      </p>
    </div>
  );
}

function MobileManagingEmployee({ image, employeeName, employeeInfo }) {
  return (
    <div className="mt-2">
      <img src={image} className="max-h-48 mx-auto rounded-full " />
      <h2 className="font-bTitle font-bold text-xl xl:text-3xl mt-8">
        {" "}
        {employeeName}{" "}
      </h2>
      <p className="font-sTitle font-normal text-sm xl:text-md mt-4">
        {" "}
        {employeeInfo}{" "}
      </p>
    </div>
  );
}
