import React from "react";
import { useState } from "react";
import OurExperience from './views/experience'
import Landing from './views/landing'
import AboutUs from './views/aboutUs'
import logo from "./assets/logo.png";
import { useEffect } from "react";


//const [files, setFiles] = useState()



function App() {

  const width = window.innerWidth

  document.title = "The Appleby Group"
  const pages = {
    Home: <Landing />,
    "About Us": <AboutUs />,
    "Our Experiences": <OurExperience />,
  };

  const [currentPage, setCurrentPage] = useState("Home");
  const [windowWidth, setWindowWidth] = useState(width)

  useEffect(() => {
      setWindowWidth(window.innerWidth)
  },[])


  const currSizeHeader = width > 800 ? <DesktopHeader currentPage={currentPage} setCurrentPage={setCurrentPage} /> : <MobileHeader currentPage={currentPage} setCurrentPage={setCurrentPage} /> 
  return (
    <div>
      {currSizeHeader}
      {pages[currentPage]}

    </div>
    
  );
}


function DesktopHeader({ currentPage, setCurrentPage }) {
  const styles = {
    active: "underline",
  };

  const PageName = ({ title }) => {
    return (
      <a
        onClick={() => setCurrentPage(title)}
        className={currentPage === title ? styles.active : ""}
      >
        {title}
      </a>
    );
  };

  return (
    <header className="w-9/12 m-16 flex flex-row justify-between items-center">
       <img
            src={logo}
            className="h-16 xl:h-12 pr-2 "
          />
      <h3 className="w-1/3 font-bTitle font-bold text-lg xl:text-2xl pr-4 border-r border-black">
        The Appleby Group
      </h3>
      <ul className="w-full font-bTitle pl-8 space-x-8 flex flex-row justify-start align-middle text-md xl:text-lg">
        <PageName title="Home" />
        <PageName title="About Us" />
        <PageName title="Our Experiences" />
      </ul>
    </header>
  );
}

function MobileHeader({ currentPage, setCurrentPage }) {
  const styles = {
    active: "underline",
  };

  const PageName = ({ title }) => {
    return (
      <a
        onClick={() => setCurrentPage(title)}
        className={currentPage === title ? styles.active : ""}
      >
        {title}
      </a>
    );
  };

  return (
    <header className=" my-4 mx-2 flex flex-row items-center">
  <img
            src={logo}
            className="h-4 pr-1 "
          />
      <h3 className="pr-2 font-bTitle font-bold text-sm border-r border-black">
        The Appleby Group
      </h3>
      <ul className="font-bTitle pl-2 space-x-2 flex flex-row justify-start align-middle text-xs ">
        <PageName title="Home" />
        <PageName title="About Us" />
        <PageName title="Our Experiences" />
      </ul>
    </header>
  );
}

export default App;

/* 

	Chris Mouriopoulos
	Martin Morin
	Mauricio Vivas
	Mike Smith
	Luis Gonzalez
	Homer Arvanitis
	Jim Ridge
	Grant Douangsouri
	Randy Jenkins
	Ted Long
	Allen Ko
	Candice St. Cin

*/
