import React from "react";
import chessImage from "../assets/chessImage.png";
import star from "../assets/star.png";
import triangle from "../assets/triangle.png";
import pentagon from "../assets/pentagon.png";
import diamond from "../assets/diamond.png";
import stationImage from "../assets/stationImage.png";
import quotes from "../assets/quotes.png";
import backdrop from "../assets/greyBackdrop.png";
import computerImage from "../assets/computerImage.png";

const width = window.innerWidth;

const DesktopLanding = () => {
  return (
    <div className="overflow-scroll">
      <div className="">
        <section className="ml-16 pt-8">
          <div className="w-1/2 ">
            <h4 className="text-sm xl:text-md font-sTitle text-grey tracking-wider">
              WE AIM TO PROVIDE VALUE AND BUILD RELATIONSHIPS
            </h4>
            <h1 className="text-4xl xl:text-6xl font-bTitle">
              Strategy and Implementation Consultants in Manufacturing
            </h1>
            <p className="mt-4 font-sTitle">
              The Appleby Group team members are more experienced with providing
              hands on training, coaching, and mentoring and less experienced
              with providing reports and recommendations for you to struggle
              with on your own. So be prepared for a high level of engagement as
              leaders, managers, and associates. Everyone will be invited to
              participate, teachable methods will be employed, and better
              results will be enjoyed by all levels of your team.
            </p>
          </div>

          <img
            src={chessImage}
            className="w-5/12 mt-16 absolute inset-y-0 right-0"
          />
        </section>

        <section className="pt-8 mt-64">
          <div className="flex flex-col w-full align-middle items-center">
            <h4 className="text-sm xl:text-md font-sTitle text-betaGray tracking-wider">
              SERVICES
            </h4>
            <h1 className="text-5xl xl:text-8xl font-bTitle text-wolfGray">
              
              What We Do
            </h1>
          </div>

          <p className="mt-4 font-sTitle text-sm w-3/5 ml-auto mr-auto">
            Our players are technical experts in all aspects of Operational
            Excellence and at the same time very skilled as change agents that
            have mastered the emotional side of change. Our individual years of
            experience are matched to your specific needs and the mountains you
            must climb over to reach that ideal state. Think of us as your
            Sherpa. We have climbed those mountains many times before. But you
            need to climb with us to gain the confidence and skills to repeat
            and sustain in the future.
          </p>

          <div className="flex flex-row w-full items-center justify-center">
            <img src={computerImage} className="w-1/3 h-1/3  " />

            <div className="w-1/2 m-16 flex flex-row flex-wrap">
              <DoThing
                image={triangle}
                title="Technical Consulting"
                paragraph="Our team members have honed their skills to such a degree that they immediately recognize what their clients REALLY need and what they are already good at. We embrace the highest level of Operational Excellence skill sets and tools along with the best in class coaching techniques on how to organize, deploy, and use those tools with the resources you have within your organizations. If you can embrace the concept of driving these skills deep within your teams, then you can guarantee long term results, long after we depart."
              />
              <DoThing
                image={diamond}
                title="Private Equity Teams "
                paragraph="We have a unique focus aimed at filling a gap that most PE firms face. That is to truly understand the locked in potential of their potential “buys” and how to unlock that potential before they become “sells”. Our ability to quickly see the embedded losses within a company, both hard and soft, comes from years of coaching and mentoring hundreds of different companies. If it’s EVA you are looking to drive, you should consider involving The Appleby Group at the early stages of your acquisitions."
              />

              <DoThing
                image={star}
                title="Supply Chain Optimization"
                // paragraph="2020 revealed itself as one of the most challenging years on our Supply Chains. All the aspects that you were accustomed to were pushed to new levels. Many failed, many had to change fast, but almost every supply chain out there needs to improve. You can look to the Appleby Group to help you prioritize those improvements and support you with hands on guidance ."
                paragraph="2020 pushed everyone's Supply Chains to new levels of turmoil. We can help
                you to create customer centric value by focusing on improving reliability of
                your end to end processes. We employ a more agile and responsive approach
                that compliments today's changing demands. We start by understanding your
                business need and collaborating with your team to create a current state
                baseline. Then we align that with key stakeholders to develop a roadmap to
                overcome identified gaps and then coach or work directly with teams to
                execute that plan.  Some examples of offerings include inventory
                optimization & PFEP development, scheduling, warehouse optimization,
                transportation and logistics improvement, value stream mapping for ideal
                state, planning, sourcing strategies and more."
              />

              <DoThing
                image={pentagon}
                title="Leading Change"
                paragraph="If you research the failures out there you will see one common root cause, “The inability to manage the people side of change.” The Appleby team members are expert in guiding their clients with another set tools, those needed to optimize the emotional side of improvement. These tools are teachable and should also land deep within your team members just as rational improvement tools should."
              />
            </div>
          </div>
        </section>

        <section className="pt-8 h-1/2 mt-32">
          <img src={quotes} className="relative z-10 w-1/12 h-1/12" />
          <h2 className="relative z-40 ml-16 -mt-8 font-bTitle font-medium text-wolfGray text-2xl xl:text-4xl ">
            We Train. We Coach. We Mentor.
          </h2>
          <div className="flex flex-row">
            <p className="relative w-6/12 z-40 ml-16 mt-16 font-sTitle font-thin text-wolfGray text-2xl xl:text-4xl ">
              Our past clients can explain their experiences much better than us
              — ask us for personal references.
            </p>
            <img
              src={stationImage}
              className="w-4/12 z-30 absolute right-0 -mt-64"
            />
            <img src={backdrop} className=" z-20 absolute right-0 -mt-96 " />
          </div>
        </section>

        <section className="relative ml-16 z-50 mt-64 mb-32 pt-64 font-bTitle text-3xl xl:text-5xl text-center">
          <h4 className="inline"> Contact us about </h4>
          <a
            className="inline font-bord underline"
            href="mailto:chris@applebygroup.ca?subject=New Engagement"
          >
            
            new engagements
          </a>
        </section>
      </div>
    </div>
  );
};

const MobileLanding = () => {
  return (
    <div className="overflow-scroll">
      <div className="">
        <section className="mx-8 pt-4">
          <div className=" ">
            <h4 className="text-sm font-sTitle text-grey tracking-wider">
              WE AIM TO PROVIDE VALUE AND BUILD RELATIONSHIPS
            </h4>
            <h1 className="text-4xl font-bTitle">
              Strategy and Implementation Consultants in Manufacturing
            </h1>
            <p className="mt-2 font-sTitle">
              The Appleby Group team members are more experienced with providing
              hands on training, coaching, and mentoring and less experienced
              with providing reports and recommendations for you to struggle
              with on your own. So be prepared for a high level of engagement as
              leaders, managers, and associates. Everyone will be invited to
              participate, teachable methods will be employed, and better
              results will be enjoyed by all levels of your team.
            </p>
          </div>
        </section>

        <section className="pt-8 mt-16 mx-8">
          <div className="flex flex-col align-middle items-center">
            <h4 className="text-sm xl:text-md font-sTitle text-betaGray tracking-wider">
              SERVICES
            </h4>
            <h1 className="text-4xl xl:text-8xl font-bTitle text-wolfGray">
              What We Do
            </h1>
          </div>

          <p className="mt-4 font-sTitle text-sm ">
            Our players are technical experts in all aspects of Operational
            Excellence and at the same time very skilled as change agents that
            have mastered the emotional side of change. Our individual years of
            experience are matched to your specific needs and the mountains you
            must climb over to reach that ideal state. Think of us as your
            Sherpa. We have climbed those mountains many times before. But you
            need to climb with us to gain the confidence and skills to repeat
            and sustain in the future.
          </p>

          <div className="flex flex-row items-center justify-center">
            <div className="mt-4 flex flex-row flex-wrap">
              <DoThing
                image={pentagon}
                title="Leading Change"
                paragraph="If you research the failures out there you will see one common root cause, “The inability to manage the people side of change.” The Appleby team members are expert in guiding their clients with another set tools, those needed to optimize the emotional side of improvement. These tools are teachable and should also land deep within your team members just as rational improvement tools should."
              />

              <DoThing
                image={diamond}
                title="Private Equity Teams "
                paragraph="We have a unique focus aimed at filling a gap that most PE firms face. That is to truly understand the locked in potential of their potential “buys” and how to unlock that potential before they become “sells”. Our ability to quickly see the embedded losses within a company, both hard and soft, comes from years of coaching and mentoring hundreds of different companies. If it’s EVA you are looking to drive, you should consider involving The Appleby Group at the early stages of your acquisitions."
              />

              <DoThing
                image={star}
                title="Supply Chain "
                paragraph="2020 pushed everyone's Supply Chains to new levels of turmoil. We can help
                you to create customer centric value by focusing on improving reliability of
                your end to end processes. We employ a more agile and responsive approach
                that compliments today's changing demands. We start by understanding your
                business need and collaborating with your team to create a current state
                baseline. Then we align that with key stakeholders to develop a roadmap to
                overcome identified gaps and then coach or work directly with teams to
                execute that plan.  Some examples of offerings include inventory
                optimization & PFEP development, scheduling, warehouse optimization,
                transportation and logistics improvement, value stream mapping for ideal
                state, planning, sourcing strategies and more."
              />
              <DoThing
                image={triangle}
                title="Technical Consulting"
                paragraph="Our team members have honed their skills to such a degree that they immediately recognize what their clients REALLY need and what they are already good at. We embrace the highest level of Operational Excellence skill sets and tools along with the best in class coaching techniques on how to organize, deploy, and use those tools with the resources you have within your organizations. If you can embrace the concept of driving these skills deep within your teams, then you can guarantee long term results, long after we depart."
              />
            </div>
          </div>
        </section>

        <section className="pt-8 mx-8 mt-16">
          <img src={quotes} className="relative z-10 w-2/12 h-1/12" />
          <h2 className="relative z-40 w-1/2 -mt-8 font-bTitle font-medium text-wolfGray text-xl ">
            We Train. We Coach. We Mentor.
          </h2>
          <div className="flex flex-row">
            <p className="relative z-40 mt-16 font-bTitle font-thin text-wolfGray text-lg ">
              Our past clients can explain their experiences much better than us
              — ask us for personal references.
            </p>
            <img
              src={stationImage}
              className="w-5/12 z-30 absolute right-0 -mt-32"
            />
          </div>
        </section>

        <section className="relative mx-8 mt-48 mb-32 font-bTitle text-lg text-center">
          <h4 className="inline"> Contact us about </h4>
          <a
            className="inline font-bord underline"
            href="mailto:chris@applebygroup.ca?subject=New Engagement"
          >
            new engagements
          </a>
        </section>
      </div>
    </div>
  );
};
export default function Landing() {
  return width > 800 ? <DesktopLanding /> : <MobileLanding />;
}

function DoThing({ image, title, paragraph }) {
  return width > 800 ? (
    <div className="w-1/2 p-4">
      <img src={image} className="" />
      <h3 className="font-bTitle text-lg xl:text-2xl text-wolfGray font-medium mt-4">
        {title}
      </h3>
      <p className="font-sTitle text-sm text-betaGray mt-4"> {paragraph} </p>
    </div>
  ) : (
    <div className="w-1/2 p-2">
      <img src={image} className="" />
      <h3 className="font-bTitle text-sm xl:text-2xl text-wolfGray font-medium mt-4">
        {title}
      </h3>
      <p className="font-sTitle text-xs xl:text-md text-betaGray mt-2 xl:mt-4">
        
        {paragraph}
      </p>
    </div>
  );
}
